@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Fira+Sans:wght@400&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  user-select: none;
  cursor: default;
  margin: 0;
}

html {
  background: #204155;
  background: radial-gradient(circle, #2A376C 0%, #204155 80%, #000000 200%);
  background-size: 1200px 1200px;
  background-repeat: revert;
  background-position: 50vw -40vh;
  background-attachment: fixed;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  margin: max(1rem, 2vw) max(1rem, 5vw);
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}